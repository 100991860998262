<template>
    <modal-lateral ref="modalCrearCombo" titulo="Buscar producto para añadir">
        <div class="row mx-0 justify-center my-3">
            <div class="col-10">
                <input-cedis-productos ref="input_cedis_productos" v-model="buscar" :adicional="{id_kit:id_kit}" @productosObtenidos="setProductos" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 137px)">
            <div class="row mx-0 justify-center"  id="loading-agregar_producto">
                <div class="col-10 px-0">
                    <div v-for="producto, index in productos" :key="index" class="d-flex">
                        <div class="col-auto px-0">
                            <img :src="producto.imagen" width="89" height="89" class="obj-cover" />
                        </div>
                        <div class="col">
                            <p class="text-general f-14 nombre f-500">
                                {{producto.nombre}}
                            </p>
                            <p class="text-general2 f-14 my-1">
                                {{producto.presentacion}}
                            </p>
                            <div v-if="producto.promocion==1">
                                <div class="d-flex mx-0 my-1">
                                    <div class="col-auto px-0">
                                        <p class="text-general f-14">
                                            <b>
                                                {{ convertMoney(producto.data_promocion.promo_valor,id_moneda) }}
                                            </b>
                                        </p>
                                    </div>
                                    <div class="col-auto">
                                        <p class="text-gris descuento f-14">
                                            <b>
                                                {{ convertMoney(producto.precio,id_moneda) }}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="bg-general text-white f-12 br-20 px-2">
                                        {{ producto.data_promocion.texto }}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="d-flex mx-0 my-1">
                                    <div class="col-auto px-0">
                                        <p class="text-general f-14">
                                            <b>
                                                {{ convertMoney(producto.precio,id_moneda) }}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto d-middle-center px-0">
                            <div class="bg-general text-white br-5 cr-pointer">
                                <i class="icon-plus f-17" @click="agregarProducto(producto.id)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import {mapGetters} from 'vuex'
import CedisKits from '~/services/cedis/cedis_kits'
export default {
    data(){
        return {
            buscar: '',
            img_producto: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            productos : [],
            id_kit : null
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
        }),
    },
    methods: {
        toggle(id_kit){
            this.id_kit = id_kit
            this.$refs.modalCrearCombo.toggle();
        },
        setProductos(productos){
            this.productos = productos
        },
        async agregarProducto(id_producto){
            try {
                let loading = this.$loading(
                {
                    lock: true,
                    target:`#loading-agregar_producto`
                });

                let datos = {
                    id_kit : this.id_kit,
                    id_producto : id_producto
                }

                const { data } = await CedisKits.AgregarProducto(datos)
                this.$refs.input_cedis_productos.buscarProductos()
                this.$emit('recargarProductos')
                loading.close()

            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style>

</style>
